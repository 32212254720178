<script setup lang="ts">
import { useSurfaceStore } from '@@/pinia/surface'
import { storeToRefs } from 'pinia'

const props = withDefaults(
  defineProps<{
    isSmallerThanTabletPortrait?: boolean
    hasSquareShape?: boolean
  }>(),
  {
    isSmallerThanTabletPortrait: false,
    hasSquareShape: false,
  },
)

const surfaceStore = useSurfaceStore()
const { onboardingDemoButtonStyle } = storeToRefs(surfaceStore)

const circleClasses = (isLarger: boolean) => [
  'rounded-full add-post-button-animation-skin-color z-wishlist absolute',
  isLarger
    ? 'animate-demo-padlet-circle-2-animation demo-padlet-circle-2-animation'
    : 'animate-demo-padlet-circle-1-animation demo-padlet-circle-1-animation',
  props.isSmallerThanTabletPortrait
    ? isLarger
      ? 'w-10 h-10'
      : 'w-8 h-8'
    : isLarger
    ? 'w-12 h-12 -end-1'
    : 'w-10 h-10',
  getBottomClass(isLarger),
]

const getBottomClass = (isLarger: boolean) => {
  if (props.hasSquareShape) {
    if (props.isSmallerThanTabletPortrait) {
      return isLarger ? 'bottom-0' : 'bottom-1'
    } else {
      return isLarger ? '-bottom-1' : 'bottom-0'
    }
  }
  if (props.isSmallerThanTabletPortrait) {
    return isLarger ? 'bottom-[-3px]' : 'bottom-[1px]'
  }
  return isLarger ? 'bottom-[-7px]' : 'bottom-[-3px]'
}
</script>

<template>
  <div :class="['relative', props.isSmallerThanTabletPortrait ? 'flex grow justify-center' : 'inline-block']">
    <!-- Larger circle -->
    <div :class="circleClasses(true)" :style="onboardingDemoButtonStyle" />
    <!-- Smaller circle -->
    <div :class="circleClasses(false)" :style="onboardingDemoButtonStyle" />
    <div class="relative z-10">
      <slot />
    </div>
  </div>
</template>

<style>
.add-post-button-animation-skin-color {
  background-color: var(--wall-skin-accent-color);
  color: var(--wall-skin-accent-text-color);
}
</style>
